<template>
  <el-row class="approve-result zt-block">
    <el-col v-if="isApprove">
      <el-radio-group v-model="isApproved">
        <el-radio :label="true">审批通过</el-radio>
        <el-radio :label="false">审批不通过</el-radio>
      </el-radio-group>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="formCheck"
        label-position="top"
      >
        <el-form-item
          label="指定代表产品实物"
          prop="item"
          v-if="isApproved && $store.state.loginUser.comp.comp_type === 9901"
        >
          <el-input v-model="ruleForm.item" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item
          label="审批金额"
          prop="applyAmount"
          v-if="isApproved && $store.state.loginUser.comp.comp_type === 9903"
        >
          <el-input
            oninput="value=value.replace(/[^\d.]/g,'')"
            v-model="ruleForm.applyAmount"
            style="width: 200px"
          ></el-input
          >万元
        </el-form-item>
        <el-form-item v-if="!isApproved" label="原因" prop="reason">
          <el-input
            type="text-area"
            v-model="ruleForm.reason"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="$router.go(-1)">返回</el-button>
          <el-button @click="resetForm('formCheck')">重置</el-button>
          <el-button type="primary" @click="submitForm('formCheck')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-col>
    <el-col v-else>
      <el-button @click="$router.go(-1)">返回</el-button>
    </el-col>
  </el-row>
</template>
<script>
export default {
  props: {
    isApprove: {
      type: Boolean,
      default: false,
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const itemValidate = (rule, value, cb) => {
      if (this.isApproved) {
        if (!value) {
          cb(new Error("请输入指定代表产品实物!"));
        } else {
          cb();
        }
      } else {
        cb();
      }
    };
    const reasonValidate = (rule, value, cb) => {
      if (!this.isApproved) {
        if (!value) {
          cb(new Error("请输入原因!"));
        } else {
          cb();
        }
      } else {
        cb();
      }
    };
    return {
      isApproved: true,
      rules: {
        item: [
          {
            validator: itemValidate,
            trigger: "blur",
          },
        ],
        reason: [
          {
            validator: reasonValidate,
            trigger: "blur",
          },
        ],
        applyAmount: [
          {
            required: true,
            trigger: "blur",
          },
        ],
      },
      ruleForm: {
        reason: null,
        item: null,
        applyAmount: null,
      },
    };
  },
  watch: {
    isApproved(val) {
      this.ruleForm.item = "";
      this.ruleForm.applyAmount = "";
      this.ruleForm.reason = "";
    }
  },
  methods: {
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$emit("submit", {
            ...this.ruleForm,
            isApproved: this.isApproved,
          });
          // this.$message.success("提交成功!");
        } else {
          this.$message.error("提交数据有误!");
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    
  },
};
</script>
<style lang="less" scoped>
.approve-result {
  padding: 20px;
}
</style>